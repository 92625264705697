import map from "lodash/map";
import tinymce from "tinymce";
import { uctrans } from "../../../../../js/lib/Translator";
import { route } from "../../../../../js/helpers";

// eslint-disable-next-line
tinymce.PluginManager.add("custom_links", function (editor, url) {
	let node = null;
	let typeOptions = [];
	let selectedType = "external";

	let currentUrl = "";
	let currentOrigin = "";
	let currentName = "";
	let currentAnchor = "";
	let currentNewTab = false;
	let changeCustomName = true;
	let existingNode = false;

	const pageTypes = {
		internal: {
			prefix: "/page/",
			text: uctrans("cms.options.internal"),
			checkEmpty: true,
			getItems: () => {
				const internalPages = editor.getParam("internal");
				const currentPage = internalPages ? internalPages.find(page => `${page.value}` === `${currentUrl}`) : [];
				const currentAnchors = currentPage && currentPage.url_anchors ? currentPage.url_anchors : [];

				return [
					{
						type: "selectbox",
						name: "url",
						label: uctrans("cms.options.internal"),
						items: internalPages.map(page => ({ text: page.label, value: `${page.value}` })),
						flex: true,
						disabled: false,
					},
					{
						type: "selectbox",
						name: "anchor",
						label: uctrans("general.url_anchor"),
						items: currentAnchors.map(anchor => ({ text: `${anchor}`, value: `${anchor}` })),
						flex: true,
						disabled: currentAnchors.length === 0,
					},
					{
						type: "checkbox",
						name: "customName",
						disabled: false,
						label: uctrans("general.custom_name"),
					},
					{
						type: "checkbox",
						name: "newTab",
						disabled: false,
						label: uctrans("cms.attributes.newtab"),
					},
				];
			},
			setDefaultName(data) {
				setDefaultNameSelectbox(data);
			},
		},
		external: {
			prefix: null,
			text: uctrans("cms.options.external"),
			checkEmpty: false,
			getItems: () => [
				{
					type: "input",
					name: "url",
					disabled: false,
					label: uctrans("general.url"),
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameInput(data);
			},
		},
		mailto: {
			prefix: "mailto:",
			text: uctrans("cms.options.mailto"),
			checkEmpty: false,
			getItems: () => [
				{
					type: "input",
					name: "url",
					disabled: false,
					label: uctrans("general.email"),
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
			],
			setDefaultName(data) {
				setDefaultNameInput(data);
			},
		},
		news: {
			prefix: "/news/",
			text: uctrans("cms.options.news"),
			checkEmpty: true,
			getItems: () => [
				{
					type: "selectbox",
					name: "url",
					label: uctrans("cms.attributes.newsitem"),
					items: editor.getParam("news").map(news => ({ text: news.label, value: `${news.value}` })),
					disabled: false,
					flex: true,
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameSelectbox(data);
			},
		},
		document: {
			data: editor.getParam("document"),
			prefix: "/download-document/",
			text: uctrans("cms.options.document"),
			checkEmpty: true,
			getItems: () => [
				{
					type: "input",
					name: "url",
					disabled: true,
					label: uctrans(`media.document.singular`),
				},
				{
					type: "button",
					text: uctrans("media.select_:media", {}, { media: `media.document.singular` }),
					primary: true,
					name: "document_picker",
					disabled: false,
					borderless: false,
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
			],
			setDefaultName(data) {
				setDefaultNameInput(data);
			},
		},
		definitions: {
			prefix: "/definition-tooltip/",
			text: uctrans("cms.options.definition_tooltip"),
			checkEmpty: true,
			getItems: () => [
				{
					type: "selectbox",
					name: "url",
					label: uctrans("cms.attributes.definition"),
					items: editor.getParam("definitions").map(page => ({ text: page.label, value: `${page.value}` })),
					disabled: false,
					flex: true,
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
			],
			setDefaultName(data) {
				setDefaultNameSelectbox(data);
			},
		},
		ctLandingOptions: {
			prefix: "/client-treatment-landing/",
			text: uctrans("cms.options.clienttreatment_landing"),
			checkEmpty: true,
			getItems: () => [
				{
					type: "selectbox",
					name: "url",
					label: uctrans("cms.attributes.ct_landing"),
					items: editor.getParam("ctLandingOptions").map(page => ({ text: page.label, value: `${page.value}` })),
					disabled: false,
					flex: true,
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameSelectbox(data);
			},
		},
		questionnaireOptions: {
			prefix: "/vragenlijst/",
			text: uctrans("cms.options.questionnaires"),
			checkEmpty: true,
			getItems: () => [
				{
					type: "selectbox",
					name: "url",
					label: uctrans("cms.options.questionnaires"),
					items: editor
						.getParam("questionnaireOptions")
						.map(questionnaire => ({ text: questionnaire.label, value: `${questionnaire.value}` })),
					disabled: false,
					flex: true,
				},
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameSelectbox(data);
			},
		},
		msBookingsRmOptions: {
			prefix: null,
			text: uctrans("cms.options.rm_ms_bookings"),
			checkEmpty: false,
			getItems: () => [
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameInput(data);
			},
		},
		msBookingsTeamOptions: {
			prefix: null,
			text: uctrans("cms.options.team_ms_bookings"),
			checkEmpty: false,
			getItems: () => [
				{
					type: "checkbox",
					name: "customName",
					disabled: false,
					label: uctrans("general.custom_name"),
				},
				{
					type: "checkbox",
					name: "newTab",
					disabled: false,
					label: uctrans("cms.attributes.newtab"),
				},
			],
			setDefaultName(data) {
				setDefaultNameInput(data);
			},
		},
	};

	// Work around for TinyMCE dropdownlist
	function setDefaultNameSelectbox(data) {
		const currentData = editor.getParam(selectedType);
		if (currentData) {
			const selectedItem = currentData.find(e => `${e.value}` === `${data.url}`);
			const label = selectedItem ? selectedItem.label : "";
			data.name = label;
			currentName = label;
		}
	}

	function setDefaultNameInput(data) {
		data.name = data.url;
		currentName = data.url;
	}

	function getDialogConfig() {
		return {
			title: uctrans(existingNode ? "general.edit_:item" : "general.create_:item", {}, { item: "general.link" }),
			body: {
				type: "panel",
				items: [
					...[
						{
							type: "selectbox",
							name: "type",
							label: uctrans("cms.attributes.target"),
							items: typeOptions,
							disabled: false,
							flex: true,
						},
						{
							type: "input",
							name: "name",
							disabled: !changeCustomName,
							label: "Text to display",
						},
					],
					...(pageTypes[selectedType] ? pageTypes[selectedType].getItems() : []),
				],
			},
			size: "normal",
			buttons: [
				{
					icon: null,
					text: "Close",
					type: "cancel",
					onclick: "close",
					align: "start",
					disabled: false,
				},
				{
					icon: null,
					text: "Insert",
					type: "submit",
					primary: true,
					align: "end",
					disabled: false,
				},
			],
			// Initial data when opening the editor with existing data
			initialData: {
				type: selectedType,
				url: currentUrl,
				name: currentName,
				newTab: currentNewTab,
				anchor: currentAnchor,
				customName: changeCustomName,
			},
			onAction(dialogApi, details) {
				if (details.name === "document_picker") {
					const document_picker = editor.getParam("document_picker");
					document_picker(dialogApi);
				}
			},
			onChange(dialogApi) {
				const data = dialogApi.getData();
				let updateDialog = false;

				if (changeCustomName !== data.customName) {
					updateDialog = true;
				}

				if (data.type === "internal" && currentUrl !== data.url) {
					updateDialog = true;
					data.anchor = "";
				}

				if (selectedType !== data.type) {
					updateDialog = true;
					data.url = "";
				}

				// update scope
				currentUrl = data.url;
				currentName = data.name;
				selectedType = data.type;
				changeCustomName = data.customName === undefined ? changeCustomName : data.customName;
				currentNewTab = data.newTab === undefined ? currentNewTab : data.newTab;
				currentAnchor = data.anchor === undefined ? currentAnchor : data.anchor;

				// Selected page type custom onChange event;
				if (!data.customName) {
					pageTypes[selectedType].setDefaultName(data);
				}

				if (updateDialog) {
					dialogApi.redial(getDialogConfig());
				} else {
					dialogApi.setData(data);
				}
			},
			onSubmit(dialogApi) {
				const data = dialogApi.getData();

				if (data.type === "msBookingsRmOptions") {
					data.url = route("website.microsoft-bookings-relationmanager-appointment");
				} else if (data.type === "msBookingsTeamOptions") {
					data.url = route("website.team-microsoft-bookings");
				}

				if (data.url) {
					const { anchor, newTab } = data;
					const postfix = anchor ? `#${anchor}` : "";

					let { prefix } = pageTypes[data.type];

					let { name } = data;
					if (!name) {
						pageTypes[data.type].setDefaultName(data);
						name = currentName;
					}

					if (data.type === "external") {
						prefix = data.url.indexOf("http") !== 0 ? "https://" : "";
					}

					if (data.type === "document") {
						const documents = editor.getParam(data.type);
						if (documents) {
							const selectedItem = documents.find(e => e.name === data.url);
							const value = selectedItem ? selectedItem.id : "";
							data.url = value;
							currentUrl = value;
						}
					}

					const attrs = { href: `${prefix}${data.url}${postfix}`, target: newTab ? "_blank" : "_self" };
					const newNode = editor.dom.createHTML("a", attrs, editor.dom.encode(name));

					if (existingNode) {
						editor.dom.remove(node);
						editor.selection.setContent(newNode);
						editor.undoManager.add();
					} else {
						editor.insertContent(newNode);
					}

					dialogApi.close();
				}
			},
		};
	}

	editor.ui.registry.addButton("custom_links", {
		tooltip: uctrans("general.link"),
		icon: "link",
		onAction() {
			// reset
			typeOptions = [];
			selectedType = "external";
			currentUrl = "";
			currentOrigin = "";
			currentName = "";
			currentNewTab = false;
			existingNode = false;
			changeCustomName = true;

			// check existing a href
			node = editor.selection.getNode();
			if (node && node.href) {
				existingNode = true;
				currentUrl = node.protocol === "mailto:" ? node.href : node.pathname;

				// if the anchor node contains query strings, append them into the url (pathname doesn't do that)
				if (node.protocol !== "mailto:" && typeof node.search === "string" && node.search.length > 0) {
					currentUrl += node.search;
				}

				currentOrigin = node.origin;
				currentName = node.innerText;
				currentNewTab = node.hash === "#newtab" || node.target === "_blank";
				currentAnchor = node.hash.replace(/#|#newtab/g, "");
			}

			// loop through types
			map(pageTypes, (type, key) => {
				const data = editor.getParam(key);
				if (!type.checkEmpty || (data && data.length)) {
					typeOptions.push({ value: key, text: type.text });
				}

				// check existing link prefix
				if (existingNode && currentUrl.indexOf(type.prefix) === 0) {
					selectedType = key;
				}
			});

			if (existingNode) {
				// replace url for user
				currentUrl = currentUrl.replace(pageTypes[selectedType].prefix, "");
				if (selectedType === "document") {
					const documents = editor.getParam(selectedType);
					if (documents) {
						const currentDocument = documents.find(e => e.id === parseInt(currentUrl));
						currentUrl = currentDocument ? currentDocument.name : uctrans("dataimports.errors.file_not_found");
					}
				}
				if (selectedType === "external") {
					currentUrl = currentOrigin + currentUrl;
				}
			} else {
				// default select
				selectedType = typeOptions[0].value;
				const selectedText = editor.selection.getContent({ format: "text" });
				if (selectedText.length > 0) {
					currentName = selectedText;
				}
			}

			// opens dialog
			editor.windowManager.open(getDialogConfig());
		},
	});

	return {
		getMetadata() {
			return {
				title: "custom_links plugin",
			};
		},
	};
});
