import map from "lodash/map";
import find from "lodash/find";
import tinymce from "tinymce";
import { uctrans } from "../../../../../js/lib/Translator";

// eslint-disable-next-line
tinymce.PluginManager.add("tags", function (editor, url) {
	let selectedTag = "";
	let selectedTitle = "";
	let selectedDescription = "";
	let tagTypes = [];
	let existingTag = false;

	function getDialogConfig() {
		return {
			title: uctrans(existingTag ? "general.edit_:item" : "general.create_:item", {}, { item: "general.tag" }),
			body: {
				type: "panel",
				items: [
					{
						type: "selectbox",
						name: "tag",
						label: uctrans("communication.tags.settings.fields.tag"),
						items: tagTypes,
						flex: true,
					},
					{
						type: "input",
						name: "title",
						disabled: true,
						label: uctrans("communication.tags.settings.fields.title"),
					},
					{
						type: "textarea",
						name: "description",
						disabled: true,
						label: uctrans("communication.tags.settings.fields.description"),
					},
				],
			},
			initialData: {
				tag: selectedTag,
				title: selectedTitle,
				description: selectedDescription,
			},
			onSubmit(dialogApi) {
				const data = dialogApi.getData();
				editor.insertContent(data.tag);
				dialogApi.close();
			},
			onChange(dialogApi) {
				const data = dialogApi.getData();

				const tag = find(tagTypes, type => type.value === data.tag);
				if (tag) {
					data.title = tag.title;
					data.description = tag.description;
				}

				dialogApi.setData(data);
			},
			buttons: [
				{
					text: "Close",
					type: "cancel",
					onclick: "close",
				},
				{
					text: "Insert",
					type: "submit",
					primary: true,
				},
			],
		};
	}

	editor.ui.registry.addButton("tags", {
		tooltip: uctrans("general.tag"),
		icon: "code-sample",
		onAction() {
			// map tags to array
			const tags = editor.getParam("tags");
			tagTypes = map(tags, type => ({
				value: `{${type.tag}}`,
				text: `{${type.tag}}`,
				title: type.title,
				description: type.description,
			}));

			// check existing tag
			const node = editor.selection.getNode();
			let tag = tagTypes.find(type => type.value === node.innerText);
			existingTag = !!tag;
			if (!tag && tagTypes.length > 0) {
				// eslint-disable-next-line prefer-destructuring
				tag = tagTypes[0];
			}

			if (tag) {
				selectedTag = tag.value;
				selectedTitle = tag.title;
				selectedDescription = tag.description;
			}

			editor.windowManager.open(getDialogConfig());
		},
	});

	return {
		getMetadata() {
			return {
				title: "tags plugin",
			};
		},
	};
});
