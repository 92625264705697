import tinymce from "tinymce";

import "tinymce/icons/default";
import "tinymce/themes/silver";

import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";
import "tinymce/plugins/autoresize";
import "tinymce/models/dom";

import "../components/tinymce/customTags";
import "../components/tinymce/customLinks";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";

tinymce.addI18n("nl", {
	Redo: "Opnieuw uitvoeren",
	Undo: "Ongedaan maken",
	Cut: "Knippen",
	Copy: "Kopi\u00ebren",
	Paste: "Plakken",
	"Select all": "Alles selecteren",
	"New document": "Nieuw document",
	Ok: "OK",
	Cancel: "Annuleren",
	"Visual aids": " Visuele hulpmiddelen",
	Bold: "Vet",
	Italic: "Cursief",
	Underline: "Onderstrepen",
	Strikethrough: "Doorhalen",
	Superscript: "Superscript",
	Subscript: "Subscript",
	"Clear formatting": "Opmaak wissen",
	"Align left": "Links uitlijnen",
	"Align center": "Centreren",
	"Align right": "Rechts uitlijnen",
	Justify: "Uitvullen",
	"Bullet list": "Lijst met opsommingstekens",
	"Numbered list": "Genummerde lijst",
	"Decrease indent": "Inspringing verkleinen",
	"Increase indent": "Inspringing vergroten",
	Close: "Sluiten",
	Formats: "Opmaken",
	"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
		"Jouw browser ondersteunt geen rechtstreekse toegang tot het klembord. Gebruik in plaats daarvan de sneltoetsen Ctrl+X/C/V.",
	Headers: "Kopteksten",
	"Header 1": "Koptekst 1",
	"Header 2": "Koptekst 2",
	"Header 3": "Koptekst 3",
	"Header 4": "Koptekst 4",
	"Header 5": "Koptekst 5",
	"Header 6": "Koptekst 6",
	Headings: "Koppen",
	"Heading 1": "Kop 1",
	"Heading 2": "Kop 2",
	"Heading 3": "Kop 3",
	"Heading 4": "Kop 4",
	"Heading 5": "Kop 5",
	"Heading 6": "Kop 6",
	Preformatted: "Vooraf opgemaakt",
	Div: "Div",
	Pre: "Pre",
	Code: "Code",
	Paragraph: "Alinea",
	Blockquote: "Blockquote",
	Inline: "Inline",
	Blocks: "Blokken",
	"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.":
		"Plakken gebeurt nu als platte tekst. Tekst wordt nu ingevoegd zonder opmaak tot deze optie uitgeschakeld wordt.",
	Fonts: "Lettertypes",
	"Font Sizes": "Tekengroottes",
	Class: "Klasse",
	"Browse for an image": "Afbeelding zoeken",
	OR: "OF",
	"Drop an image here": "Hier een afbeelding neerzetten",
	Upload: "Uploaden",
	Block: "Blok",
	Align: "Uitlijnen",
	Default: "Standaard",
	Circle: "Cirkel",
	Disc: "Bolletje",
	Square: "Vierkant",
	"Lower Alpha": "Kleine letters",
	"Lower Greek": "Griekse letters",
	"Lower Roman": "Romeinse cijfers klein",
	"Upper Alpha": "Hoofdletters",
	"Upper Roman": "Romeinse cijfers groot",
	"Anchor...": "Anker...",
	Name: "Naam",
	Id: "ID",
	"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.":
		"ID moet beginnen met een letter, gevolgd door letters, nummers, streepjes, punten, dubbele punten of underscores.",
	"You have unsaved changes are you sure you want to navigate away?":
		"U hebt niet alles opgeslagen bent u zeker dat u de pagina wenst te verlaten?",
	"Restore last draft": "Herstel het laatste concept",
	"Special characters...": "Speciale tekens...",
	"Source code": "Broncode",
	"Insert/Edit code sample": "Broncode invoegen/bewerken",
	Language: "Programmeertaal",
	"Code sample...": "Codevoorbeeld...",
	"Color Picker": "Kleurenkiezer",
	R: "Rood",
	G: "Groen",
	B: "Blauw",
	"Left to right": "Links naar rechts",
	"Right to left": "Rechts naar links",
	"Emoticons...": "Emoticons...",
	"Metadata and Document Properties": "Metadata en documenteigenschappen",
	Title: "Titel",
	Keywords: "Sleutelwoorden",
	Description: "Omschrijving",
	Robots: "Robots",
	Author: "Auteur",
	Encoding: "Codering",
	Fullscreen: "Volledig scherm",
	Action: "Actie",
	Shortcut: "Snelkoppeling",
	Help: "Help",
	Address: "Adres",
	"Focus to menubar": "Menubalk selecteren",
	"Focus to toolbar": "Werkbalk selecteren",
	"Focus to element path": "Element pad selecteren",
	"Focus to contextual toolbar": "Contextuele werkbalk selecteren",
	"Insert link (if link plugin activated)": "Link invoegen (als link plug-in geactiveerd is)",
	"Save (if save plugin activated)": "Opslaan (als opslaan plug-in ingeschakeld is)",
	"Find (if searchreplace plugin activated)": "Zoeken (als zoeken/vervangen plug-in ingeschakeld is)",
	"Plugins installed ({0}):": "Plug-ins ge\u00efnstalleerd ({0}):",
	"Premium plugins:": "Premium plug-ins:",
	"Learn more...": "Leer meer...",
	"You are using {0}": "Je gebruikt {0}",
	Plugins: "Plug-ins",
	"Handy Shortcuts": "Handige snelkoppelingen",
	"Horizontal line": "Horizontale lijn",
	"Insert/edit image": "Afbeelding invoegen/bewerken",
	"Image description": "Afbeelding omschrijving",
	Source: "Bron",
	Dimensions: "Afmetingen",
	"Constrain proportions": "Verhoudingen behouden",
	General: "Algemeen",
	Advanced: "Geavanceerd",
	Style: "Stijl",
	"Vertical space": "Verticale ruimte",
	"Horizontal space": "Horizontale ruimte",
	Border: "Rand",
	"Insert image": "Afbeelding invoegen",
	"Image...": "Afbeelding...",
	"Image list": "Afbeeldingenlijst",
	"Rotate counterclockwise": "Linksom draaien",
	"Rotate clockwise": "Rechtsom draaien",
	"Flip vertically": "Verticaal spiegelen",
	"Flip horizontally": "Horizontaal spiegelen",
	"Edit image": "Bewerk afbeelding",
	"Image options": "Afbeelding opties",
	"Zoom in": "Inzoomen",
	"Zoom out": "Uitzoomen",
	Crop: "Uitsnijden",
	Resize: "Formaat aanpassen",
	Orientation: "Orientatie",
	Brightness: "Helderheid",
	Sharpen: "Scherpte",
	Contrast: "Contrast",
	"Color levels": "Kleurniveau's",
	Gamma: "Gamma",
	Invert: "Omkeren",
	Apply: "Toepassen",
	Back: "Terug",
	"Insert date/time": "Voeg datum/tijd in",
	"Date/time": "Datum/tijd",
	"Insert/Edit Link": "Link invoegen/bewerken",
	"Insert/edit link": "Hyperlink invoegen/bewerken",
	"Text to display": "Linktekst",
	Url: "Url",
	"Open link in...": "Link openen in...",
	"Current window": "Huidige venster",
	None: "Geen",
	"New window": "Nieuw venster",
	"Remove link": "Link verwijderen",
	Anchors: "Anker",
	"Link...": "Link...",
	"Paste or type a link": "Plak of typ een link",
	"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?":
		'De ingegeven URL lijkt op een e-mailadres. Wil je er "mailto:" aan toevoegen?',
	"The URL you entered seems to be an external link. Do you want to add the required http:// prefix?":
		'De ingegeven URL verwijst naar een extern adres. Wil je er "http://" aan toevoegen?',
	"Link list": "Linklijst",
	"Insert video": "Video invoegen",
	"Insert/edit video": "Video invoegen/bewerken",
	"Insert/edit media": "Media invoegen/bewerken",
	"Alternative source": "Alternatieve bron",
	"Alternative source URL": "Alternatief bron-URL",
	"Media poster (Image URL)": "Mediaposter (afbeeldings-url)",
	"Paste your embed code below:": "Plak u in te sluiten code hieronder:",
	Embed: "Insluiten",
	"Media...": "Media...",
	"Nonbreaking space": "Vaste spatie invoegen",
	"Page break": "Pagina einde",
	"Paste as text": "Plakken als tekst",
	Preview: "Voorbeeld",
	"Print...": "Afdrukken... ",
	Save: "Opslaan",
	Find: "Zoeken",
	"Replace with": "Vervangen door",
	Replace: "Vervangen",
	"Replace all": "Alles vervangen",
	Previous: "Vorige",
	Next: "Volgende",
	"Find and replace...": "Zoeken en vervangen...",
	"Could not find the specified string.": "Geen resultaten gevonden",
	"Match case": "Identieke hoofd/kleine letters",
	"Find whole words only": "Alleen hele woorden zoeken",
	"Spell check": "Spellingscontrole",
	Ignore: "Negeren",
	"Ignore all": "Alles negeren",
	Finish: "Einde",
	"Add to Dictionary": "Toevoegen aan woordenlijst",
	"Insert table": "Tabel invoegen",
	"Table properties": "Tabel eigenschappen",
	"Delete table": "Verwijder tabel",
	Cell: "Cel",
	Row: "Rij",
	Column: "Kolom",
	"Cell properties": "Cel eigenschappen",
	"Merge cells": "Cellen samenvoegen",
	"Split cell": "Cel splitsen",
	"Insert row before": "Voeg rij boven toe",
	"Insert row after": "Voeg rij onder toe",
	"Delete row": "Verwijder rij",
	"Row properties": "Rij eigenschappen",
	"Cut row": "Knip rij",
	"Copy row": "Kopieer rij",
	"Paste row before": "Plak rij boven",
	"Paste row after": "Plak rij onder",
	"Insert column before": "Voeg kolom in voor",
	"Insert column after": "Voeg kolom in na",
	"Delete column": "Verwijder kolom",
	Cols: "Kolommen",
	Rows: "Rijen",
	Width: "Breedte",
	Height: "Hoogte",
	"Cell spacing": "Celruimte",
	"Cell padding": "Ruimte binnen cel",
	"Show caption": "Bijschrift weergeven",
	Left: "Links",
	Center: "Midden",
	Right: "Rechts",
	"Cell type": "Celtype",
	Scope: "Bereik",
	Alignment: "Uitlijning",
	"H Align": "Links uitlijnen",
	"V Align": "Boven uitlijnen",
	Top: "Bovenaan",
	Middle: "Centreren",
	Bottom: "Onderaan",
	"Header cell": "Kopcel",
	"Row group": "Rijgroep",
	"Column group": "Kolomgroep",
	"Row type": "Rijtype",
	Header: "Koptekst",
	Body: "Body",
	Footer: "Voettekst",
	"Border color": "Randkleur",
	"Insert template...": "Sjabloon invoegen...",
	Templates: "Sjablonen",
	Template: "Sjabloon",
	"Text color": "Tekstkleur",
	"Background color": "Achtergrondkleur",
	"Custom...": "Eigen...",
	"Custom color": "Eigen kleur",
	"No color": "Geen kleur",
	"Remove color": "Kleur verwijderen",
	"Table of Contents": "Inhoudsopgave",
	"Show blocks": "Blokken tonen",
	"Show invisible characters": "Onzichtbare karakters tonen",
	"Word count": "Aantal woorden",
	"Words: {0}": "Woorden: {0}",
	"{0} words": "{0} woorden",
	File: "Bestand",
	Edit: "Bewerken",
	Insert: "Invoegen",
	View: "Beeld",
	Format: "Opmaak",
	Table: "Tabel",
	Tools: "Gereedschap",
	"Powered by {0}": "Gemaakt door {0}",
	"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help":
		"Rich Text Area. Druk ALT-F9 voor het menu. Druk ALT-F10 voor de toolbar. Druk ALT-0 voor help.",
	"Image title": "Afbeeldingstitel",
	"Border width": "Randbreedte",
	"Border style": "Randstijl",
	Error: "Fout",
	Warn: "Waarschuwen",
	Valid: "Geldig",
	"To open the popup, press Shift+Enter": "Druk op Shift+Enter om de pop-up te openen",
	"Rich Text Area. Press ALT-0 for help.": " Gebied met opgemaakte tekst. Druk op ALT-0 voor hulp.",
	"System Font": "Systeemlettertype",
	"Failed to upload image: {0}": "Niet gelukt om afbeelding te uploaden: {0}",
	"Failed to load plugin: {0} from url {1}": "Niet gelukt om plug-in te laden: {0} vanaf URL {1}",
	"Failed to load plugin url: {0}": "Niet gelukt om URL plug-in te laden: {0}",
	"Failed to initialize plugin: {0}": "Niet gelukt om plug-in te initialiseren: {0}",
	example: "voorbeeld",
	Search: "Zoeken",
	All: "Alle",
	Currency: "Valuta",
	Text: "Tekst",
	Quotations: "Citaten",
	Mathematical: "Wiskundig",
	"Extended Latin": "Latijn uitgebreid ",
	Symbols: "Symbolen",
	Arrows: "Pijlen",
	"User Defined": "Door gebruiker gedefinieerd ",
	"dollar sign": "dollarteken",
	"currency sign": "valutateken",
	"euro-currency sign": "euroteken",
	"colon sign": "colon-teken",
	"cruzeiro sign": "cruzeiro-teken",
	"french franc sign": "franse franc-teken",
	"lira sign": "lire-teken",
	"mill sign": "mill-teken",
	"naira sign": "naira-teken",
	"peseta sign": "peseta-teken",
	"rupee sign": "roepie-teken",
	"won sign": "won-teken",
	"new sheqel sign": "nieuwe sheqel-teken",
	"dong sign": "dong-teken",
	"kip sign": "kip-teken",
	"tugrik sign": "tugrik-teken",
	"drachma sign": "drachme-teken",
	"german penny symbol": "duitse pfennig-teken",
	"peso sign": "peso-teken",
	"guarani sign": "guarani-teken",
	"austral sign": "austral-teken",
	"hryvnia sign": "hryvnia-teken",
	"cedi sign": "cedi-teken",
	"livre tournois sign": "livre tournois-teken",
	"spesmilo sign": "spesmilo-teken",
	"tenge sign": "tenge-teken",
	"indian rupee sign": "indiaase roepie-teken",
	"turkish lira sign": "turkse lire-teken",
	"nordic mark sign": "noorse mark-teken",
	"manat sign": "manat-teken",
	"ruble sign": "roebel-teken",
	"yen character": "yen-teken",
	"yuan character": "yuan-teken",
	"yuan character, in hong kong and taiwan": "yuan-teken (Hong Kong en Taiwan)",
	"yen/yuan character variant one": "yen/yuan variant 1-teken",
	"Loading emoticons...": "Emoticons laden...",
	"Could not load emoticons": "Kan emoticons niet laden",
	People: "Personen",
	"Animals and Nature": "Dieren en natuur",
	"Food and Drink": "Eten en drinken",
	Activity: "Activiteit",
	"Travel and Places": "Reizen en plaatsen",
	Objects: "Objecten",
	Flags: "Vlaggen",
	Characters: "Tekens",
	"Characters (no spaces)": "Tekens (geen spaties)",
	"Error: Form submit field collision.": "Fout: Veldconflict bij versturen formulier.",
	"Error: No form element found.": "Fout: Geen formulierelement gevonden.",
	Update: "Bijwerken",
	"Color swatch": "Kleurenwaaier",
	Turquoise: "Turquoise",
	Green: "Groen",
	Blue: "Blauw",
	Purple: "Paars",
	"Navy Blue": "Marineblauw",
	"Dark Turquoise": "Donkerturquoise",
	"Dark Green": "Donkergroen",
	"Medium Blue": "Middelblauw",
	"Medium Purple": "Middelpaars",
	"Midnight Blue": "Middernachtblauw",
	Yellow: "Geel",
	Orange: "Oranje",
	Red: "Rood",
	"Light Gray": "Lichtgrijs",
	Gray: "Grijs",
	"Dark Yellow": "Donkergeel",
	"Dark Orange": "Donkeroranje",
	"Dark Red": "Donkerrood",
	"Medium Gray": "Middelgrijs",
	"Dark Gray": "Donkergrijs",
	Black: "Zwart",
	White: "Wit",
	"Switch to or from fullscreen mode": "Overschakelen naar of vanuit de volledig scherm-modus",
	"Open help dialog": "Help-scherm openen",
	history: "geschiedenis",
	styles: "stijlen",
	formatting: "opmaak",
	alignment: "uitlijning",
	indentation: "inspringing",
	"permanent pen": "permanent pen",
	comments: "opmerkingen",
	Anchor: "Anker",
	"Special character": "Speciale karakters",
	"Code sample": "Broncode voorbeeld",
	Color: "Kleur",
	Emoticons: "Emoticons",
	"Document properties": "Document eigenschappen",
	Image: "Afbeelding",
	"Insert link": "Hyperlink invoegen",
	Target: "Doel",
	Link: "Link",
	Poster: "Poster",
	Media: "Media",
	Print: "Print",
	Prev: "Vorige",
	"Find and replace": "Zoek en vervang",
	"Whole words": "Alleen hele woorden",
	Spellcheck: "Spellingscontrole",
	Caption: "Onderschrift",
	"Insert template": "Sjabloon invoegen",
	Yes: "Ja",
	No: "Nee",
});

export default tinymce;
